import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import Spinner from '../../../fragments/Spinner';
import fetch from '../../../utils/fetch';
import MathMd from '../../../components/Renderer/math-md';
import { ERROR_PALE, PRIMARY_APP, SUCCESS_PALE } from '../../../constants/colors';
import { getAnswerSubmitURL } from '../../../constants/app';
import { TQuestion } from '../../../@types';

import Answer from './Answer';

interface IProps {
  exerciseId: string;
  API_URL: string;
  question: TQuestion;
  lastResponse: { [key: string]: any };
  testMode?: boolean;
  isLastQuestion?: boolean;
  questionNo: number;
  onAnswer: (answerResponse: any) => void;
}

export default function (props: IProps) {
  const { API_URL, exerciseId, question, lastResponse: lastResponseInitial, onAnswer, isLastQuestion = false } = props;
  const { text, explanation, _id: questionId, ...questionData } = question;

  const [error, setError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [answer, setAnswer] = useState<any>(lastResponseInitial);
  const [lastResponse, setlastResponse] = useState<any>(lastResponseInitial);
  const maybeEvaluation = lastResponse?.evaluation;

  const submitAnswer = async () => {
    if (!questionId) return;
    if (!answer) return setError('Make an answer before submitting!');

    const body = JSON.stringify({ questionId, exerciseId, ...answer });
    setSubmitting(true);
    setError('');
    const resp = await fetch(getAnswerSubmitURL(API_URL), {
      body,
      method: 'POST',
      credentials: 'header',
      headers: { 'Content-Type': 'application/json' },
    });
    try {
      const respJSON = await resp.json();
      if (resp.status !== 200) {
        setSubmitting(false);
        setError(respJSON.error);
        return;
      }

      setlastResponse(respJSON.response);
      onAnswer(respJSON.response);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setError('Unable to reach server. Please try again in a while.');
      return;
    }
  };

  const answered = lastResponse.answered;
  const answeredCorrectly = maybeEvaluation?.is_correct_answer;
  const noPoints = !maybeEvaluation?.points;
  const locked = lastResponse?.locked;

  const answerData = {
    answer,
    id: questionId,
    setAnswer,
    response: lastResponse,
    API_URL,
    setError,
    ...questionData,
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitAnswer();
      }}
    >
      <div className="proc-m-4 proc-mt-0">
        <div className="proc-flex proc-justify-between">
          <div className="proc-mx proc-w-full proc-mt-4">
            <span>
              <MathMd value={'Q.' + props.questionNo + ' ' + text} />
            </span>
          </div>
          {answered ? (
            <div
              className={`proc-text-white proc-text-bold proc-text-xs
                proc-px-4 proc-py-1 proc-rounded-b proc-uppercase proc-self-start
                ${noPoints ? 'proc-bg-brand-light' : answeredCorrectly ? 'proc-bg-green-600' : 'proc-bg-red-600'}
              `}
            >
              {noPoints ? 'Answered' : answeredCorrectly ? 'Correct' : 'Incorrect'}
            </div>
          ) : null}
        </div>
        <Answer {...answerData} />
        {error && <div className="proc-mt-2 proc-text-sm proc-text-red-500">{error}</div>}
        <div className="proc-flex proc-justify-between proc-items-center proc-mt-2">
          <button
            disabled={locked}
            type="submit"
            className={`proc-button proc-flex proc-items-center ${locked ? 'proc-secondary proc-disabled' : ''}`}
          >
            {submitting && <Spinner size="tiny" className="mr-3" />}
            {!isLastQuestion ? `Submit${submitting ? 'ting' : ''}` : 'Submit & End Test'}
          </button>
          {explanation && (
            <div className="proc-flex proc-items-center">
              <span className="proc-font-sm proc-mr-2">
                <FaInfoCircle color={PRIMARY_APP} />
              </span>
              <MathMd value={explanation} />
            </div>
          )}
        </div>
      </div>
      <div className="proc-hr proc-mb-0" />
    </form>
  );
}
