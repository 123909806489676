import React, { useEffect, useState } from 'react';

import Spinner from '../../fragments/Spinner';
import Error from '../../fragments/Error';
import { getExerciseURL, getExerciseSubmitURL } from '../../constants/app';
import fetch from '../../utils/fetch';
import { TExercise, TQuestionData, TScore } from '../../@types';

import Question from './Question';
import HorizontalQuestions from './HorizontalMode';
import Countdown from 'react-countdown';

interface IProps {
  id: string;
  API_URL: string;
  onComplete?: (score?: TScore) => void;
  testMode?: boolean;
}

export default function Exercise(props: IProps) {
  const { id: exerciseId, onComplete, API_URL, testMode = false } = props;

  const [exercise, setExercise] = useState<TExercise>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isTestMode, setIsTestMode] = useState<boolean>(testMode);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [isTestCompleted, setIsTestCompleted] = useState<boolean>(false);
  //const [isTestTabInView, setIsTestTabInView] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [errorpreset, seterrorpreset] = useState<string>('broken');
  const [answeredCount, setansweredCount] = useState<number>(0);
  const [questionCount, setquestionCount] = useState<number>(0);

  // document.addEventListener('visibilitychange', (event) => {
  //   if (document.visibilityState == 'visible') {
  //     setIsTestTabInView(true);
  //   } else {
  //     //hidden
  //     console.log(document.visibilityState);
  //     setIsTestTabInView(false);
  //   }
  // });
  // window.addEventListener('blur', (event) => {
  //   setIsTestTabInView(false);
  // });

  // window.addEventListener('focus', (event) => {
  //   setIsTestTabInView(true);
  // });
  // useEffect(() => {
  //   console.log(isTestTabInView);
  //   if (!isTestTabInView && alertShownCount < 3) {
  //     alert('Please Do not move away from window when giving test ! Actions will be logged');
  //     alertShownCount += 1;
  //   }
  // }, [isTestTabInView]);
  const checkComplete = async () => {
    if (!exerciseId) return;
    let resp, respJSON, respText;
    try {
      resp = await fetch(getExerciseSubmitURL(API_URL, exerciseId), { credentials: 'header' });
      setLoading(false);
      respText = await resp.text();

      if (respText) respJSON = JSON.parse(respText);
      if (respJSON.success) setIsTestCompleted(true);
      if (onComplete) {
        onComplete(respJSON);
      }
    } catch (e) {
      if (respJSON.error) respText = respJSON.error;
      else respText = 'Unable to reach server';
      setError(respText);
    }
  };

  const endTest = async () => {
    //alert user of the attempted questions
    if (answeredCount != questionCount) {
      const confirmation = confirm(
        `You have attempted ${answeredCount} questions out of ${questionCount} you really want to submit the test ?`
      );
      if (confirmation) {
        checkComplete();
      }
    } else {
      const confirmation = confirm(`You have attempted all the questions. Submit test ?`);
      if (confirmation) {
        checkComplete();
      }
    }
  };

  const fetchExercises = async () => {
    if (!exerciseId) return;
    let resp, respJSON, respText;
    try {
      resp = await fetch(getExerciseURL(API_URL, exerciseId), { credentials: 'header' });
      setLoading(false);
      respText = await resp.text();
      if (respText) respJSON = JSON.parse(respText);
    } catch (e) {
      if (resp?.status === 403) seterrorpreset('pay2win');
      if (!respText) respText = 'Unable to reach server';
      setError(respText);
      return;
    }

    if (resp.status !== 200) {
      setError(respJSON.error);
      return;
    }

    setExercise(respJSON);
    setEndDate(Date.now() + respJSON.remainingTime * 1000);
    setIsTestCompleted(respJSON.submitted);
    const { questions, mode } = respJSON;
    if (!questions || questions.length <= 0) {
      return null;
    }
    if (['ee', 'exam'].includes(mode)) {
      setIsTestMode(true);
    } else {
      setIsTestMode(false);
    }

    const questionCount = questions.length;
    const answeredCount = questions.reduce(
      (acc: number, cur: TQuestionData) => (cur.last_response?.locked ? acc + 1 : acc),
      0
    );
    setquestionCount(questionCount);
    setansweredCount(answeredCount);

    //if (answeredCount === questionCount) checkComplete();
  };

  const onAnswer = (answerResponse: any) => {
    if (!onComplete || !answerResponse.locked) return;

    const newAnsweredCount = answeredCount + 1;
    if (newAnsweredCount === questionCount) checkComplete();
    setansweredCount(answeredCount + 1);
  };

  const OnTimeComplete = () => <span>Time Is Up !</span>;
  // Renderer callback with condition
  const countDownRenderer = ({ hours = 0, minutes = 0, seconds = 0, completed = false }) => {
    const pad = (n: number) => (Math.trunc(n / 10) == 0 ? '0' + n : n);
    if (completed) {
      // Render a completed state
      return <OnTimeComplete />;
    } else {
      // Render a countdown
      return (
        <span>
          {pad(hours)}:{pad(minutes)}:{pad(seconds)}
        </span>
      );
    }
  };

  useEffect(() => {
    fetchExercises();
  }, [exerciseId]);

  if (loading)
    return (
      <div className="h-screen">
        <Spinner bar />
      </div>
    );
  if (!exerciseId) return <Error preset="broken" text="Incorrect URL - exercise ID is missing." />;
  if (error) return <Error preset={errorpreset} text={error} />;
  if (!exercise) return <Error preset="broken" text="Exercise does not seem to exist" />;
  const { title, description, questions } = exercise;

  const params = {
    API_URL: API_URL,
    exerciseId: exerciseId,
    onAnswer: onAnswer,
  };
  if (isTestCompleted || exercise.remainingTime === 0)
    return (
      <Error
        emoji="ヽ(^o^)ノ"
        emotext="Test Completed"
        text={`Your ${isTestMode ? 'Test' : 'Quiz'}  is submitted Successfully`}
      />
    );
  return (
    <div>
      {!questions || questions.length <= 0 ? (
        <Error emoji="¯\_( ͡❛ ͜ʖ ͡❛)_/¯" emotext="Not Found" text="No questions found for this exercise." />
      ) : (
        <div className="proc-base proc-mx-4">
          <div className="proc-flex proc-items-center proc-justify-between proc-my-2 md:proc-my-0">
            <h3 className="proc-h3 proc-w-8/12">{title}</h3>
          </div>
          {endDate ? (
            <div className="proc-flex proc-flex-row proc-place-content-end">
              Remaining Time &nbsp;&nbsp;{' '}
              <Countdown
                date={endDate}
                zeroPadTime={3}
                className={
                  exercise.remainingTime < 600 && exercise.remainingTime > 300
                    ? 'text-yellow-500  font-bold'
                    : exercise.remainingTime < 600
                    ? 'text-red-500 font-bold'
                    : ''
                }
                onComplete={() => {
                  checkComplete();
                }}
                renderer={countDownRenderer}
              ></Countdown>
            </div>
          ) : (
            ''
          )}
          {description && <div className="proc-mb">{description}</div>}
          {isTestMode ? null : <div className="proc-hr proc-mb-0" />}
          {isTestMode ? (
            <HorizontalQuestions questions={questions} questionCount={questionCount} {...params} />
          ) : (
            questions.map((qn: TQuestionData, idx: number) => (
              <Question
                key={`e-${exerciseId}-q-${qn.question._id}`}
                question={qn.question}
                lastResponse={qn.last_response}
                questionNo={idx + 1}
                {...params}
              />
            ))
          )}
          <div className="proc-end-test-btn">
            <button
              className="button  proc-button proc-flex proc-items-center "
              onClick={() => {
                endTest();
              }}
            >
              {isTestMode ? 'End Test' : 'End Quiz'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
