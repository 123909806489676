import React from 'react';
// import { FaCheck, FaTimes } from 'react-icons/fa';

import { IQuestionProps } from './Answer';
import { TAnswerMeta } from '../../../components/TAUserExercise/Question';
import MathMd from '../../../components/Renderer/math-md';

type TOption = {
  _id: string;
  text: string;
};

type TRenderOptions = {
  answer: string[];
  response?: TAnswerMeta;
  data: TOption[];
  id: string;
  setAnswer: (answer: string[]) => void;
};

function RenderOptions(props: TRenderOptions) {
  const { response, answer, data, id, setAnswer } = props;

  // const isEvaluated = response?.evaluation;
  // const isCorrect = response?.evaluation?.is_correct_answer;
  const lock = response?.locked;

  const onChooseAnswer = (selectedId: string) => {
    if (!answer) return setAnswer([selectedId]);

    const newAnswer = answer.includes(selectedId)
      ? answer.filter((a: string) => a !== selectedId)
      : [...answer, selectedId];
    setAnswer(newAnswer);
  };

  return (
    <div className="proc-my-2">
      {data.map((o: TOption) => {
        // const isSelected = answer?.includes(o._id);

        return (
          <div key={o.text} className="proc-flex proc-flex-row proc-items-center proc-my-4">
            {/* <div className="proc-w-4 proc-mr-2">
              {isSelected && isEvaluated && !isCorrect && <FaTimes className="proc-text-red-600" />}
              {isSelected && isCorrect && <FaCheck className="proc-text-green-600" />}
            </div> */}
            <label className="proc-flex proc-items-center proc-flex-1">
              <input
                disabled={lock}
                name={id}
                type="checkbox"
                onChange={() => onChooseAnswer(o._id)}
                checked={answer?.includes(o._id)}
                value={o.text}
              />
              <MathMd value={o.text} className="proc-ml-2" />
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default function (props: IQuestionProps) {
  const { answer, response, id, options, setAnswer } = props;

  const onSelectOption = (ids: string[]) => {
    setAnswer({ selected_option: ids });
  };
  const chosenIds = answer?.selected_option;

  return (
    <RenderOptions answer={chosenIds} response={response} id={`q-${id}`} data={options} setAnswer={onSelectOption} />
  );
}
