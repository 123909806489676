import React, { useEffect, useState } from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';

import { TQuestionData } from '../../@types';
import Error from '../../fragments/Error';
import Question from './Question';

interface IProps {
  questions: TQuestionData[];
  API_URL: string;
  exerciseId: string;
  questionCount: number;
  onAnswer: (answerResponse: any) => void;
}

export default function HorizontalMode(props: IProps) {
  const { questions, exerciseId, questionCount } = props;
  const [currentQuestionIdx, setcurrentQuestionIdx] = useState<number>(0);

  const goPreviousQuestion = () => {
    if (currentQuestionIdx <= 0) return;
    setcurrentQuestionIdx(currentQuestionIdx - 1);
  };

  const goNextQuestion = () => {
    const nextIdx = currentQuestionIdx + 1;
    if (nextIdx >= questionCount) return;
    setcurrentQuestionIdx(currentQuestionIdx + 1);
  };

  const goToQuestion = (index: number) => {
    if (index > questionCount) return;
    setcurrentQuestionIdx(index);
  };
  const onAnswer = (answerResponse: any) => {
    goNextQuestion();
    questions[currentQuestionIdx].last_response = answerResponse;
    props.onAnswer(answerResponse);
  };

  if (!questions) return null;
  const currentQuestion = questions[currentQuestionIdx];
  if (!currentQuestion) return null;
  const isPreviousDisabled = currentQuestionIdx <= 0;
  const isNextDisabled = currentQuestionIdx + 1 >= questionCount;

  return (
    <div className="proc-grid proc-grid-cols-1 md:proc-grid-cols-3/1">
      <div>
        <div className="proc-w-full proc-my-4 proc-flex proc-flex-row proc-items-center proc-justify-between proc-px-4">
          <button
            onClick={goPreviousQuestion}
            className={`proc-button ${isPreviousDisabled ? 'proc-secondary proc-disabled' : ''}`}
            disabled={isPreviousDisabled}
          >
            <FaLongArrowAltLeft className="proc-inline proc-text-xs proc-mr-2" /> PREV
          </button>
          <span>
            Question {currentQuestionIdx + 1} of {questionCount}
          </span>
          <button
            onClick={goNextQuestion}
            className={`proc-button ${isNextDisabled ? 'proc-secondary proc-disabled' : ''}`}
            disabled={isNextDisabled}
          >
            NEXT <FaLongArrowAltRight className="proc-inline proc-text-xs proc-ml-2" />
          </button>
        </div>
        <div className="proc-hr proc-mb-0" />
        <Question
          {...props}
          key={`e-${exerciseId}-q-${currentQuestion.question._id}`}
          question={currentQuestion.question}
          lastResponse={currentQuestion.last_response}
          onAnswer={onAnswer}
          isLastQuestion={isNextDisabled}
          questionNo={currentQuestionIdx + 1}
        />
      </div>
      <>
        <div className="proc-justify-center proc-my-2 md:proc-my-0 proc-border proc-border-2 proc-border-light-blue-500 proc-border-solid proc-ml-4 proc-p-4">
          <h3 className="proc-h3  proc-text-center">Questions</h3>

          <div className="proc-grid proc-grid-cols-4 proc-gap-2">
            {questions.map((qstn: TQuestionData, index) => {
              return (
                <div
                  className={`proc-border-solid proc-border-2 proc-border-blue-500 proc-text-center
                 hover:proc-text-white hover:proc-bg-blue-700 hover:proc-scale-110 cursor-pointer
                 ${qstn.last_response.answered ? 'proc-bg-green-400' : ''}`}
                  onClick={() => {
                    goToQuestion(index);
                  }}
                >
                  <div className=" proc-p-1  ">
                    <span>Q.{index + 1}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
}
