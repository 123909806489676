import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';

import CodeBox from '../../Renderer/code';
import fetch from '../../../utils/fetch';
import { IQuestionProps } from './Answer';
import { getRunCodeURL } from '../../../constants/app';

type TOption = {
  id: string;
  text: string;
};

type TRenderCode = {
  code: string;
  submittedAnswer?: string;
  correctness?: number;
  lock?: boolean;
  language?: string;
  data: TOption[];
  type: string;
  id: string;
  setAnswer: (answer: string) => void;
  passKey?: (keyValue: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

function RenderCoder(props: TRenderCode) {
  const { code = '', setAnswer, language = 'python', passKey } = props;

  return <CodeBox value={code} setAnswer={setAnswer} language={language} passKey={passKey} />;
}

export default function (props: IQuestionProps) {
  const { answer, id, type, options, setAnswer, API_URL } = props;
  const [running, setrunning] = useState<boolean>(false);
  const [codeOutput, setcodeOutput] = useState<{ [key: string]: string | number }>();
  const [codeOutputError, setcodeOutputError] = useState<string>();

  const runCode = async () => {
    setrunning(true);
    const body = JSON.stringify(answer);

    try {
      const resp = await fetch(getRunCodeURL(API_URL), {
        body,
        method: 'POST',
        credentials: 'header',
        headers: { 'Content-Type': 'application/json' },
      });
      const respJSON = await resp.json();
      setrunning(false);
      if (!respJSON.success) return setcodeOutputError(respJSON.error);
      setcodeOutput(respJSON.response.run);
    } catch (e) {
      setrunning(false);
      setcodeOutputError('Something went wrong');
    }
  };

  const setCode = (code: string) => {
    setAnswer({ code: { files: [{ name: 'code.py', content: code }] } });
  };
  let codeText = '';
  try {
    codeText = answer ? answer?.code[0]?.content || answer?.code.files[0]?.content : '';
  } catch (e) {
    codeText = '';
  }

  const watchKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && event.ctrlKey) runCode();
  };

  const codeProps = {
    code: codeText,
    id: `q-${id}`,
    type,
    data: options,
    setAnswer: setCode,
    passKey: watchKeyPress,
  };

  return (
    <div className="proc-mt-2">
      <RenderCoder {...codeProps} />
      <div
        className={`proc-p-4 proc-bg-codebg proc-mb-2
          proc-border-t proc-border-white
          proc-flex items-center proc-justify-between
      `}
      >
        <div className="proc-w-full proc-mr-4 proc-text-white proc-font-mono">
          {(() => {
            if (running) return <h5 className="proc-uppercase">Running ...</h5>;
            if (codeOutputError) return <span className="proc-text-red-500">{codeOutputError}</span>;
            return codeOutput?.output;
          })()}
        </div>
        <div className="proc-px-4 proc-py-1 proc-bg-gray-600 proc-text-white proc-text-xs proc-rounded">OUTPUT</div>
      </div>
      <button
        className={`proc-button proc-inline ${answer?.locked ? 'disabled' : ''} proc-text-xs proc-tracking-narrow`}
        onClick={runCode}
        type="button"
      >
        Run Code <FaPlay className="proc-inline proc-ml-1" />
      </button>
      <span className="proc-ml-2 proc-text-gray-400 proc-text-xs">Ctrl + Enter</span>
    </div>
  );
}
