import React from 'react';

import { IQuestionProps } from './Answer';
import { TAnswerMeta } from '../../../components/TAUserExercise/Question';

type TOption = {
  id: string;
  text: string;
};

type TRenderOptions = {
  answer: string;
  response?: TAnswerMeta;
  data: TOption[];
  id: string;
  setAnswer: Function;
};

function RenderFreeAnswer(props: TRenderOptions) {
  const { answer, id, response, setAnswer } = props;

  const lock = response?.locked;

  return (
    <textarea
      className="proc-input proc-mt proc-my-2"
      disabled={lock}
      name={id}
      onChange={(e) => setAnswer(e.target.value)}
      value={answer}
    />
  );
}

export default function (props: IQuestionProps) {
  const { answer, response, id, options, setAnswer } = props;

  const onWriteAnswer = (text: string) => {
    setAnswer({ text });
  };
  const answerText = answer?.text;

  return (
    <RenderFreeAnswer answer={answerText} response={response} id={`q-${id}`} data={options} setAnswer={onWriteAnswer} />
  );
}
