import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaSpinner } from 'react-icons/fa';

import CodeBox from '../../Renderer/code';
import fetch from '../../../utils/fetch';
import { IQuestionProps } from './Answer';
import { getRunCodeURL } from '../../../constants/app';
import SplitPane, { Pane } from 'react-split-pane';
import '../../../styles/react-pane.css';

type TOption = {
  id: string;
  text: string;
};

type TRenderCode = {
  code: string;
  submittedAnswer?: string;
  correctness?: number;
  lock?: boolean;
  language?: string;
  data: TOption[];
  type: string;
  id: string;
  setAnswer: (answer: string) => void;
  passKey?: (keyValue: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

function RenderCoder(props: TRenderCode) {
  const { code = '', setAnswer, language = 'python', passKey } = props;
  return <CodeBox value={code} setAnswer={setAnswer} language={language} passKey={passKey} />;
}

export default function (props: IQuestionProps) {
  const { answer, id, type, options, setAnswer, scaffold, API_URL, datafiles } = props;
  const [running, setRunning] = useState<boolean>(false);
  const [codeOutput, setcodeOutput] = useState<{ [key: string]: string | number }>();
  const [streamlitPort, setStreamlitPort] = useState<number | undefined>(0);
  const [streamlitSessionId, setStreamlitSessionId] = useState<string | undefined>(undefined);
  const [codeOutputError, setcodeOutputError] = useState<string>();
  const streamlitIframeRef = useRef(null);
  const [r, setR] = useState<number>(Date.now());

  const checkLocallySavedSession = async () => {
    const sessionId = await localStorage.getItem(`session-${id}`);
    const port = await localStorage.getItem(`port-${id}`);

    if (sessionId && port) {
      let portNum = parseInt(port);
      setStreamlitSessionId(sessionId);
      setStreamlitPort(portNum);
    }
  };
  useEffect(() => {
    checkLocallySavedSession();
  }, []);
  const runCode = async () => {
    setRunning(true);

    const params = { ...answer, type: 'streamlit', session_id: streamlitSessionId };
    const body = JSON.stringify(params);

    try {
      const resp = await fetch(getRunCodeURL(API_URL), {
        body,
        method: 'POST',
        credentials: 'header',
        headers: { 'Content-Type': 'application/json' },
      });
      const respJSON = await resp.json();

      if (respJSON.error && (respJSON.error == 'session_expired' || respJSON.error == 'no_such_session')) {
        if (streamlitSessionId) localStorage.removeItem(streamlitSessionId);
        localStorage.removeItem(`session-${id}`);
        localStorage.removeItem(`port-${id}`);
        setStreamlitSessionId(undefined);
        setStreamlitPort(undefined);
        runCode();
      }
      if (!respJSON.success) return setcodeOutputError(respJSON.error);
      if (respJSON.response.session_id && respJSON.response.port) {
        localStorage.setItem(`session-${id}`, respJSON.response.session_id);
        localStorage.setItem(`port-${id}`, respJSON.response.port);
        setcodeOutput(respJSON.response.output);
        setStreamlitPort(respJSON.response.port);
        setStreamlitSessionId(respJSON.response.session_id);
      }

      if (streamlitSessionId) await localStorage.setItem(streamlitSessionId, JSON.stringify(answer));

      setTimeout(() => {
        setR(Date.now());
        setRunning(false);
      }, 5000);
    } catch (e) {
      setRunning(false);
      setcodeOutputError('Something went wrong');
    }
  };

  const setCode = (code: string) => {
    setAnswer({ code: { files: [{ name: 'code.py', content: code }] } });
  };

  const getLocallySavedAnswer = () => {
    if (streamlitSessionId) {
      const ans = localStorage.getItem(streamlitSessionId);

      if (ans != null) {
        const a = JSON.parse(ans);
        setAnswer(a);
        return a.code.files[0]?.content;
      }
    } else {
      return '';
    }
  };
  const getAnswerForStreamlit = (): string => {
    try {
      if (answer) {
        if (answer?.code && answer?.code[0]?.content) return answer?.code[0]?.content;
        else if (answer?.code.files[0]?.content) return answer?.code.files[0]?.content;
      } else {
        const localAns = getLocallySavedAnswer();
        if (localAns) return localAns;

        if (scaffold) return scaffold;
        return '# write your code here' + props.text;
      }
      return '# write your code here' + props.description;
    } catch (e) {
      return '# write your code here' + props.description;
    }
  };

  const codeText = getAnswerForStreamlit();
  const watchKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && event.ctrlKey) runCode();
  };

  const codeProps = {
    code: codeText,
    id: `qd-${id}`,
    type,
    data: options,
    setAnswer: setCode,
    passKey: watchKeyPress,
  };

  return (
    <div className="proc-mt-2 h-full">
      <SplitPane split="horizontal" style={{ position: 'relative' }} defaultSize={window.innerWidth < 900 ? 700 : 600}>
        <SplitPane
          split={window.innerWidth < 900 ? 'horizontal' : 'vertical'}
          minSize={100}
          maxSize={900}
          defaultSize={350}
          style={{ position: 'relative' }}
        >
          <Pane className="h-full" key={`vertical-p1-${id}`}>
            <RenderCoder {...codeProps} />
          </Pane>

          <Pane className="h-full" key={`vertical-p2-${id}`}>
            {running ? (
              <div className="h-full flex flex-col justify-center items-center">
                <FaSpinner className="animate-spin" size={56} />
                <span>Loading</span>
              </div>
            ) : (
              <iframe
                key={r}
                ref={streamlitIframeRef}
                className="min-h-full min-w-full"
                src={streamlitPort ? `https://streamlit.univ.ai/${streamlitPort}/` : 'https://streamlit.univ.ai/3999'}
                title="description"
              ></iframe>
            )}
          </Pane>
        </SplitPane>
      </SplitPane>

      {/* <div className="grid grid-cols-2 gap-4 h-24 min-h-0 md:min-h-full">
       
        <div className={`items-center `}>
          <iframe className="min-h-full min-w-full" src="https://streamlit.univ.ai/3652/" title="description"></iframe>
        </div>
      </div> */}
      <div className="proc-mt-2">
        <button
          className={`proc-button proc-inline proc-mt-2 ${
            answer?.locked ? 'disabled' : ''
          } proc-text-xs proc-tracking-narrow`}
          onClick={runCode}
          type="button"
          disabled={running}
        >
          {`${running ? 'Running' : 'Run Code'}`}
          {running ? <FaSpinner className="animate-spin" size={14} /> : null}
        </button>
        <span className="proc-ml-2 proc-text-gray-400 proc-text-xs">Ctrl + Enter</span>
      </div>
    </div>
  );
}
