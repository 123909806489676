import React from 'react';
import Radio from './Radio';
import Check from './Check';
import Free from './Free';
import Code from './Code';
import Streamlit from './Streamlit';

export interface IQuestionProps {
  [key: string]: any;
}

export default function (props: IQuestionProps) {
  const { type, ...data } = props;

  if (type === 'radio') return <Radio {...data} />;
  if (type === 'mcq') return <Check {...data} />;
  if (type === 'text') return <Free {...data} />;
  if (type === 'code') return <Code {...data} />;
  if (type === 'streamlit') return <Streamlit {...data} />;
  return null;
}
