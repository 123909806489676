import React from 'react';
// import { FaCheck, FaTimes } from 'react-icons/fa';

import { IQuestionProps } from './Answer';
import MathMd from '../../Renderer/math-md';
import { TAnswerMeta } from 'components/TAUserExercise/Question';

type TOption = {
  _id: string;
  text: string;
  points?: number;
  explanation?: string;
};

type TRenderOptions = {
  answer: string;
  response?: TAnswerMeta;
  options: TOption[];
  id: string;
  setAnswer: Function;
};

function RenderOptions(props: TRenderOptions) {
  const { answer, options, id, response, setAnswer } = props;

  // const isEvaluated = response?.evaluation;
  // const isCorrect = response?.evaluation?.is_correct_answer;
  const lock = response?.locked;

  return (
    <div className="proc-my-2">
      {options.map((o: TOption) => {
        // const isSelected = answer === o._id;

        return (
          <div key={o.text} className="proc-flex proc-flex-row proc-items-center proc-my-4">
            {/* <div className="proc-w-4 proc-mr-2">
              {isSelected && isEvaluated && !isCorrect && <FaTimes className="proc-text-red-600" />}
              {isSelected && isCorrect && <FaCheck className="proc-text-green-600" />}
            </div> */}
            <label className="proc-flex proc-items-center proc-flex-1">
              <input
                disabled={lock}
                name={id}
                type="radio"
                onChange={() => setAnswer(o._id)}
                checked={response?.selected_option?.includes(o._id)}
                value={o.text}
              />
              <MathMd value={o.text} className="proc-ml-2" />
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default function (props: IQuestionProps) {
  const { answer, response, id, options, setAnswer } = props;

  const onSelectOption = (id: string) => {
    setAnswer({ selected_option: id });
  };

  const chosenId = answer?.selected_option;

  return (
    <RenderOptions answer={chosenId} response={response} id={`q-${id}`} options={options} setAnswer={onSelectOption} />
  );
}
